import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledLine = styled.div`
  border: ${({ theme }) =>
    theme === 'light'
      ? '0.5px solid rgba(51, 51, 51, 0.1)'
      : '0.5px solid #414141'};
`;

class Line extends PureComponent {
  render() {
    return <StyledLine theme={this.props.theme} />;
  }
}

export default Line;
