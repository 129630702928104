import React from 'react';
import PropTypes from 'prop-types';

const DiceLogo = ({ size, theme }) => (
  <svg
    width={size}
    height={size}
    x="0px"
    y="0px"
    viewBox="0 0 2126 2126"
    fill={theme === 'dark' ? 'white' : 'black'}
  >
    <g>
      <path
        d="M1865.7,832.5c-33,5.6-211,35.6-248.7,41.4c-41.5,6.4-53.7,59.8-53.7,59.8l152.5-27.1c0,0,101.7-17,131-21.8
        c29.2-4.8,5.2,38.3,5.2,38.3s-81.8,23.6-115.2,33.3c-33.5,9.7-134.1,41-176.8,55.7c-42.7,14.7-41.2,21.7-65.7,51.6
        c-24.5,29.9-28.2,57.6-26.8,63.3c1.3,5.7,54.6-6.8,99.8-16.1c45.2-9.3,121-29.1,121-29.1c47.6-12.3,139.3-32.7,142.9-33
        c3.6-0.2,9.2-2,18.4,9.1c9.2,11.1-4,45.4-4,45.4c-5-5.6-28.3-3.3-28.3-3.3c-29,2.3-80.3,23.8-123.4,41.8
        c-43.2,18-121.7,37.8-143.9,44.2c-22.3,6.4-59.2,22.7-84.2,33.7c-25,11-45.4,24.3-45.4,24.3c-19.6,23.5-57.6,44.2-57.6,44.2
        s-62.2-66.6-67.8-75.7c-5.6-9.1,18.1-19.7,35.1-29.3c17-9.6,18.8-12.1,25.9-20.9c7-8.8,20.6-43.6,29-61s20.9-48.9,22.4-56.2
        c1.5-7.3-5.6-52-3.9-63.8c1.7-11.9,50.5-21.2,50.5-21.2l36.5-48.2l-33.8-19.8c0,0-4.9-15.1-10.1-35.2c-5.3-20.1,6.8-23.2,6.8-23.2
        c4.7-4.5,15.3-7.6,23.5-9.7c8.2-2.1,108.9-14.4,131.3-16.9c16.6-1.8,144.3-14,212-20.4L1064.5,31.4L32.9,1063l339.3,339.3
        l163.3-358.8c-20.3-26.6-175.4,86.7-191.2,97.3c-15.8,10.6-14.1,15.2-14.1,15.2c12.9,27.3,32.3,14.8,32.3,14.8
        c6.4,56-59.7,16.4-59.7,16.4c-34.8-48.5-51.8-47.1,39.3-122c91.1-74.8,201.9-103.3,212.5-107.5c10.6-4.3,19.4-10.3,19.4-10.3
        s13.6-38.2,17.5-47.9c3.9-9.8,7.2-15.5,15.8-13c8.6,2.4,29.8,34.1,40.3,39.5c10.5,5.3,105.4-2.2,120.1-2.2
        c14.8-0.1,24.1,0.4,32.4,8.8c8.3,8.4,47,40.7,70.3,93.7c23.3,53-72.2,158.5-134.7,221.3c-62.5,62.8-260.3,182.4-275.5,190.6
        c-14.6,7.9-20,17.4-25,27.2l629.3,629.3L2096.2,1063L1865.7,832.5z M844.6,1423.5c0,0-46.5-6.9-32.7-66.1
        c13.8-59.2,51.9-222.2,119-374.5c67.1-152.3,63.2-135.1,76-142.9c12.7-7.8,19.5-15.8,30-1.4c10.5,14.5,26.9,53.5,30.6,54.7
        c3.7,1.2-6.8,12.2-6.8,12.2s-29.8,55.1-36,67.2c-6.2,12.1-80.5,155.2-117.9,287.4C869.4,1392.2,844.6,1423.5,844.6,1423.5z
          M1019,1229.4c-30.9-36.7-9.7-104.2-9.7-104.2c100.2-213.7,239.4-277.2,259.3-291.1c19.9-13.9,36.8-3.2,36.8-3.2
        c52.4,44.2,34.1,68.8,17.3,78.1c-16.8,9.3,18.3-15.4-81.1,37.2c-99.4,52.6-169.3,186.4-175.3,217.1c-6,30.7-5.9,38.4,20.1,35.2
        c26.1-3.2,165.5-87,188.4-96.3c23-9.3,33.8,21.3,43,18.2c0,0,3.6-1.4,7,0.6c3.3,2,7.5,5.4,0.1,11.3
        c-82.2,61.1-191.4,114.6-218.3,126C1069.8,1273.6,1049.8,1266,1019,1229.4z"
      />
      <path
        d="M809.1,1067.2c20.8-29.4,12.6-47,7.9-61.4c-4.7-14.4-19.2-15.7-19.2-15.7c-30.4-8.7-122.3-0.9-133,0.7
        c-10.6,1.5-32.2,16.7-41.6,23.7c-9.5,7-30.4,58.7-30.4,58.7L481,1348.6l87.9-52.4C703.5,1205.8,788.3,1096.7,809.1,1067.2z"
      />
    </g>
  </svg>
);

DiceLogo.defaultProps = {
  size: '50',
  theme: 'light',
};

DiceLogo.propTypes = {
  size: PropTypes.string,
  theme: PropTypes.string,
};

export default DiceLogo;
