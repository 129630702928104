import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withNamespaces } from 'react-i18next';

import Event from '../components/Event';
import SkeletonEvent from '../components/SkeletonEvent';
import DiceLogo from '../styles/DiceLogo';

import * as eventsActions from '../redux/modules/events';
import formatEvent from '../utils/format-event-v2';
import getContrastColour from '../styles/get-contrast-colour';

const Page = styled.section`
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  color: ${({ theme }) => (theme === 'dark' ? 'white' : 'black')};
  padding: 16px 0;
`;

const Heading = styled.h2`
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  color: ${({ theme }) => (theme === 'dark' ? 'white' : 'black')};
  font-weight: normal;
  margin: 0;
  font-size: 24px;
`;

const EventsGrid = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-gap: 48px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: min-content;
  flex-flow: row wrap;
  padding: 24px 0;
`;

const LoadMore = styled.button`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  background: ${({ background }) => background};
  color: ${({ background }) => getContrastColour(background)};
  border: none;
  outline: none;
  padding: 12px 32px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  border-radius: ${({ roundButtons }) => (roundButtons ? '160px' : '0')};
  max-width: 165px;
  margin-bottom: 24px;
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};

  &:hover {
    opacity: 0.75;
    border: none;
    background: ${({ background }) => background};
  }
`;

const DiceLinks = styled.div`
  text-align: left;
`;

const PoweredBy = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => (theme === 'dark' ? 'white' : 'black')} !important;
  text-decoration: none;
`;

const PrivacyPolicy = styled.a`
  color: ${({ theme }) => (theme === 'dark' ? 'white' : 'black')} !important;
  text-decoration: none;
  font-family: Arial;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
`;

const PoweredByText = styled.div`
  font-family: Arial;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-right: 16px;
  color: ${({ theme }) => (theme === 'dark' ? 'white' : 'black')} !important;
`;

const Footer = styledContainerQuery.footer`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 24px 0;

  &:container(min-width: 700px) {
    flex-direction: row;
    justify-content: center;

    ${LoadMore} {
      margin: 0;
    }

    ${PoweredBy} {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    ${PrivacyPolicy} {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const EventList = (props) => {
  const {
    apiKey,
    venues,
    promoters,
    artists,
    tags,
    cities,
    currency,
    price_from: priceFrom,
    price_to: priceTo,
    layout,
    title,
    theme,
    eventActions,
    events,
    fontFamily,
    i18n,
    highlightColour,
    roundButtons,
    t,
  } = props;
  const [error, setError] = useState(false);
  useEffect(() => {
    if (apiKey) {
      eventActions.eventsRequest({
        venues,
        promoters,
        artists,
        tags,
        cities,
        currency,
        priceFrom,
        priceTo,
        apiKey,
      });
    } else {
      setError(true);
    }
  }, []);
  let Events = styled.div``;
  if (layout === 'gallery') {
    Events = EventsGrid;
  }

  if (events.fetching || !events.data) {
    return (
      <Page>
        <Events>
          <SkeletonEvent />
          <SkeletonEvent />
          <SkeletonEvent />
          <SkeletonEvent />
          <SkeletonEvent />
          <SkeletonEvent />
        </Events>
      </Page>
    );
  }

  if (error || events.error) {
    return <Page>Something has gone wrong, please get in touch with your contact at DICE</Page>;
  }

  return (
    <Page fontFamily={fontFamily} theme={theme} className="dice_event-listing-container">
      <Heading fontFamily={fontFamily} theme={theme}>{title}</Heading>
      <Events className="dice_events">
        {events.data.map(event => (
          <Event key={event.id} event={formatEvent(event, i18n.language)} {...props} />
        ))}
      </Events>
      <Footer>
        <>
          {events.links && events.links.next && (
            <LoadMore
              className="dice_load-more"
              type="button"
              background={highlightColour}
              roundButtons={roundButtons}
              fontFamily={fontFamily}
              onClick={() => {
                eventActions.eventsPaginateRequest({
                  apiKey,
                  next: events.links.next,
                });
                window.dice_ga('send', 'event', 'Event List', 'click', 'Load More');
              }}
            >
              {t('load_more')}
            </LoadMore>
          )}
          <DiceLinks>
            <PoweredBy className="dice_powered-by" href="https://dice.fm" target="_blank" rel="noopener noreferrer noskim" theme={theme}>
              <PoweredByText theme={theme}>{t('powered_by')}</PoweredByText>
              <DiceLogo theme={theme} />
            </PoweredBy>
            <PrivacyPolicy className="dice_privacy-policy" href="https://dice.fm/privacy_policy.html" target="_blank" rel="noopener noreferrer noskim" theme={theme}>{t('privacy_policy')}</PrivacyPolicy>
          </DiceLinks>
        </>
      </Footer>
    </Page>
  );
};

EventList.defaultProps = {
  venues: undefined,
  promoters: undefined,
  artists: undefined,
  tags: undefined,
  cities: undefined,
  currency: undefined,
  price_from: undefined,
  price_to: undefined,
  layout: 'list',
  title: undefined,
  theme: 'light',
  fontFamily: 'inherit',
  roundButtons: false,
};

EventList.propTypes = {
  apiKey: PropTypes.string.isRequired,
  venues: PropTypes.arrayOf(PropTypes.string),
  promoters: PropTypes.arrayOf(PropTypes.string),
  artists: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  cities: PropTypes.arrayOf(PropTypes.string),
  currency: PropTypes.string,
  price_from: PropTypes.number,
  price_to: PropTypes.number,
  layout: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  eventActions: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  fontFamily: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  highlightColour: PropTypes.string.isRequired,
  roundButtons: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  events: state.events,
});

const mapDispatchToProps = dispatch => ({
  eventActions: bindActionCreators(eventsActions, dispatch),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
)(EventList);
