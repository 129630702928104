import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const PlayAudio = ({ clip }) => {
  const audioEl = useRef(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!audioEl.current) {
      return null;
    }
    const setEnded = () => {
      setPlaying(false);
    };

    audioEl.current.addEventListener('ended', setEnded);


    return () => {
      audioEl.current.removeEventListener('ended', setEnded);
    };
  });

  if (playing && audioEl.current && audioEl.current.paused) {
    audioEl.current.play();
  }

  if (!playing && audioEl.current && !audioEl.current.paused) {
    audioEl.current.load();
  }

  return (
    <Container onClick={() => setPlaying(!playing)}>
      {!playing && (
        <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0.00134277L15 10.2699L0 20.5013V0.00134277Z" fill="white" />
        </svg>
      )}
      {playing && (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M19 17H23V33H19V17Z" fill="white" />
          <path fillRule="evenodd" clipRule="evenodd" d="M27 17H31V33H27V17Z" fill="white" />
        </svg>
      )}
      <audio ref={audioEl}>
        <source src={clip} />
      </audio>
    </Container>
  );
};

PlayAudio.propTypes = {
  clip: PropTypes.string.isRequired,
};

export default PlayAudio;
