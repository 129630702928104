import React, { PureComponent } from 'react';
import styled from 'styled-components';

import DiceLogo from '../../styles/DiceLogo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 42px;
`;

const Link = styled.a`
  display: flex;
  text-decoration: none !important;
  color: ${({ theme }) =>
    theme === 'light' ? '#000 !important' : '#FFF !important'};
  margin-bottom: 8px;
`;

const Text = styled.div`
  text-align: center;
  margin-top: 15px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0;
`;

const PrivacyPolicy = styled.a`
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0;
  text-decoration: none !important;
  color: ${({ theme }) =>
    theme === 'light' ? '#000 !important' : '#FFF !important'};
`;

class PoweredByDICE extends PureComponent {
  render() {
    return (
      <Wrapper>
        <div>
          <Link href="https://dice.fm" target="_blank" theme={this.props.theme}>
            <Text>{this.props.t('powered_by')}</Text>
            <DiceLogo theme={this.props.theme} />
          </Link>
          <PrivacyPolicy href="https://dice.fm/privacy_policy.html" target="_blank" theme={this.props.theme}>{this.props.t('privacy_policy')}</PrivacyPolicy>
        </div>
      </Wrapper>
    );
  }
}

export default PoweredByDICE;
