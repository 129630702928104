import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import EventListLegacy from './containers/EventListLegacy';
import EventList from './containers/EventList';
import configureStore from './redux/store';
import i18n from './i18n';

const store = configureStore();

/* eslint-disable */
class App extends Component {
  componentDidMount() {
    const url = window.location !== window.parent.location
      ? document.referrer
      : document.location.href;

    window.GoogleAnalyticsObject = 'dice_ga';
    const script = document.createElement('script');
    script.src = 'https://www.google-analytics.com/analytics.js';
    script.async = true;
    script.onload = function () {
      window.dice_ga =
        window.dice_ga ||
        function () {
          (dice_ga.q = dice_ga.q || []).push(arguments);
        };

      dice_ga.l = +new Date();
      dice_ga('create', 'UA-49561032-17', 'auto');
      dice_ga('send', {
        hitType: 'pageview',
        location: url,
      });
    };

    document.body.appendChild(script);
  }

  render() {
    const { version } = this.props;
    let EventListing = EventListLegacy;
    if (version && (version === 2 || version === '2')) {
      EventListing = EventList;
    }
    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <EventListing {...this.props} />
        </I18nextProvider>
      </Provider>
    );
  }
}
/* eslint-enable */

export default App;
