import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Line from './Line';
import media from '../../styles/media';

const Article = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(33% - 30px);
  padding: 15px;

  ${media.phone`
    width: 100%;
    padding: 5px 0;
  `};
`;

const SkeletonEvent = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) =>
    theme === 'light'
      ? '1px solid rgba(51, 51, 51, 0.2)'
      : '1px solid rgba(255, 255, 255, 0.2)'};
  width: 100%;
`;

const SkeletonEventInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SkeletonEventName = styled.div`
  width: 100%;
  height: 15px;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};
  margin-top: 8px;
  margin-bottom: 8px;

  ${media.phone`
    margin-top: 0;
  `};
`;

const SkeletonEventStartTime = styled.div`
  width: 80%;
  height: 15px;
  margin-bottom: 8px;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};

  ${media.phone`
    margin-bottom: 19px;
  `};
`;

const SkeletonEventBuyButton = styled.div`
  height: 45px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};

  ${media.phone`
    height: 35px;
  `};
`;

const SkeletonEventCover = styled.div`
  height: 280px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};
`;

class SkeletonEventTile extends PureComponent {
  render() {
    const { theme } = this.props;

    return (
      <Article>
        <SkeletonEvent theme={theme}>
          <SkeletonEventCover theme={theme} />
          <SkeletonEventInfo>
            <SkeletonEventName theme={theme} />
            <SkeletonEventStartTime theme={theme} />
            <SkeletonEventBuyButton theme={theme} />
          </SkeletonEventInfo>
        </SkeletonEvent>
      </Article>
    );
  }
}

export default SkeletonEventTile;
