import tinycolor from 'tinycolor2';

const getButtonColour = ({ theme, buttonBackgroundColor }) => {
  let color = tinycolor
    .mostReadable(buttonBackgroundColor, ['#FFF', '#000'])
    .toHexString();

  if (theme === 'light' && buttonBackgroundColor === 'transparent') {
    color = '#000';
  }
  if (theme === 'dark' && buttonBackgroundColor === 'transparent') {
    color = '#FFF';
  }

  return {
    background: buttonBackgroundColor,
    color,
  };
};

const getBorderColour = ({ theme, buttonBackgroundColor }) => {
  if (theme === 'light' && (buttonBackgroundColor === '#ffffff' || buttonBackgroundColor === 'transparent')) {
    return '#000';
  }
  if (theme === 'dark' && buttonBackgroundColor === 'transparent') {
    return '#FFF';
  }
  return buttonBackgroundColor;
};

export { getButtonColour, getBorderColour };
