const SYMBOL_MAP = {
  GBP: '£',
  USD: '$',
  EUR: '€',
};

export default (currency) => {
  let symbol = SYMBOL_MAP[typeof currency !== 'string' ? 'GBP' : currency];
  if (symbol == null) {
    symbol = '';
  }

  return symbol;
};
