import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatCostObj from '../utils/formatters/currency/cost-obj';

const Container = styled.div`
  background: ${({ theme }) => (theme === 'dark' ? 'rgba(127,127,127,0.2)' : 'rgba(127,127,127,0.1)')};
  margin-top: 16px;
`;

const TopExpander = styled.div`
  padding: 16px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => (theme === 'dark' ? 'white' : '#333333')};
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: rgba(127, 127, 127, 0.2);
  }
`;

const Right = styled.div`
  float: right;
`;

const EventDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  line-height: 1.4;
  font-size: 16px;
`;

const Description = styled.p`
  margin: 0;
  white-space: pre-wrap;
  margin: 8px;
  flex-basis: 320px;
  flex-grow: 1;
  max-width: 70ch;
`;

const Entry = styled.div`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled.div`
  color: ${({ colour }) => colour};
  font-size: 14px;
  letter-spacing: 1.5px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const Section = styled.div`
  margin: 8px;
  flex-basis: 160px;
  flex-grow: 1;
`;

const Soldout = styled.span`
  font-size: 12.8px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0.5;
`;

const ExtraInfo = ({ event, highlightColour, language, theme, t }) => {
  const [opened, setOpened] = useState(false);
  const symbol = opened ? '-' : '+';
  const { description, lineup, ticket_types: tickets } = event;

  return (
    <Container theme={theme}>
      <TopExpander theme={theme} opened={opened} onClick={() => setOpened(!opened)}>
        {t('more_info')}
        <Right>{symbol}</Right>
      </TopExpander>
      {opened && (
        <EventDetails>
          <Description>
            {description}
          </Description>
          <Section>
            <Heading colour={highlightColour}>{t('event.lineup')}</Heading>
            {lineup.map(entry => (
              <Entry key={entry.details}>
                {entry.details}
                {entry.time && (
                  <span> ― <strong>{entry.time}</strong></span>
                )}
              </Entry>
            ))}
          </Section>
          <Section>
            <Heading colour={highlightColour}>{t('event.tickets')}</Heading>
            {tickets.map(ticket => (
              <Entry key={ticket.id}>
                {ticket.name}
                <span> ― <strong>{formatCostObj(ticket.price, 1, language)}</strong></span>
                {ticket.sold_out && <Soldout>{t('event.sold_out')}</Soldout>}
              </Entry>
            ))}
          </Section>
        </EventDetails>
      )}
    </Container>
  );
};

ExtraInfo.propTypes = {
  event: PropTypes.object.isRequired,
  highlightColour: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default ExtraInfo;
