import formatCurrency from './currency';

export default (costObj, numberOfTickets = 1, locale = 'en') => {
  if (!costObj) {
    return '';
  }
  const cost = typeof costObj.toJSON === 'function' ? costObj.toJSON() : costObj;
  const amount = (cost.total / 100) * numberOfTickets;
  const { currency } = cost;

  if (amount === 0) return 'Free';

  return formatCurrency(amount, currency, locale);
};
