import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (process.env.NODE_ENV === 'development') {
  // dev opts
  const opts = {
    venueName: '', // the venue name - MOTH Club
    cover: false, // true - false
    buttonBackgroundColor: '#ea5d31', // '#hex'
    promoterName: '', // 'Bad Vibrations'
    artistName: '', // the artist name

    // both
    theme: 'light', // light - dark
    fontFamily: 'Helvetica', // inherit - Helvetica - Georgia
    eventStyle: 'gallery', // 'gallery'
    title: '', // 'NME PICKS'
    tags: [], // 'type:club'
    apiKey: '5X8dyqzgad3ZTkKDbQS0J2lakrd3bEli8qycYT6d', // venue's api key
    partnerId: '7d51d4a9', // partner id

    // v2
    layout: 'list',
    venues: ['Tooting Tram & Social'],
    promoters: [],
    artists: [],
    cities: [],
    currency: '',
    price_from: '',
    price_to: '',
    showImages: true,
    showAudio: true,
    information: 'full', // simple, regular, or full
    eventTitle: 'event', // event, city, or venue
    roundButtons: true,
    highlightColour: 'orange',
    version: 2,
  };
  ReactDOM.render(
    <App {...opts} />,
    document.getElementById('dice-event-list-widget'),
  );
}

class DiceEventListWidget {
  static create(opts = {}) {
    function doRender() {
      if (DiceEventListWidget.el) {
        throw new Error(
          'DiceEventListWidget is already mounted, destroy first',
        );
      }
      const el = document.getElementById('dice-event-list-widget');
      if (!el) {
        throw new Error(
          'Element with ID dice-event-list-widget must be present',
        );
      }
      ReactDOM.render(<App {...opts} />, el);
      DiceEventListWidget.el = el;
    }
    if (document.readyState === 'complete') {
      doRender();
    } else {
      window.addEventListener('load', () => {
        doRender();
      });
    }
  }

  static destroy() {
    if (!DiceEventListWidget.el) {
      throw new Error('DiceEventListWidget is not created, create first');
    }
    ReactDOM.unmountComponentAtNode(DiceEventListWidget.el);
    DiceEventListWidget.el = null;
  }
}

export default DiceEventListWidget;
