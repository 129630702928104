import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';
import moment from 'moment';

import BookNow from './BookNow';
import PlayAudio from './PlayAudio';
import ExtraInfo from './ExtraInfo';

import JSONLD from '../utils/JSONLD';
import getJSONLDFromEvent from '../utils/get-jsonld-from-event';

const WrapperNonGallery = styled.article`
  padding: 24px 0;
  border-bottom: ${({ theme }) => (theme === 'dark' ? '1px solid #ffffff33' : '1px solid #00000033')};
  
  &:last-child {
    border-bottom: none;
  }
`;

const StartTime = styled.time`
  display: block;
  margin-bottom: 4px;
`;

const OnSaleTime = styled.time`
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
`;

const Name = styled.a`
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  color: ${({ colour }) => colour};
  transition: color 0.2s ease;

  &:hover {
    color: ${({ highlightColour }) => highlightColour};
  }
`;

const NewBadge = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: bold;
  margin-left: 8px;
  color: ${({ highlightColour }) => highlightColour || 'black'};
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 8px;
`;

const EventImage = styled.img`
  display: block;
  min-width: 100%;
  max-width: 100%;
  height: auto;
`;

const VenueInfo = styled.div`
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.4;
`;

const Venue = styled.div`
  font-weight: bold;
`;

const EventInfo = styled.div`
  margin-bottom: 16px;
  flex-grow: 1;
`;

const BookNowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SimpleBookNowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styledContainerQuery.div`
  &:container(min-width: 650px) {
    display: flex;

    ${BookNowContainer} {
      flex-direction: column-reverse;
      justify-content: space-around;
      align-items: flex-end;
    }

    ${EventInfo} {
      margin-bottom: 0px;
    }
  }

  &:container(min-width: 500px) {
    ${ImageContainer} {
      min-width: 160px;
      max-width: 160px;
      margin-bottom: 0px;
      max-height: 160px;
      margin-right: 16px;
    }
    ${EventInfo} {
      display: flex;
      align-items: center;
    }
  }
`;

const Event = (props) => {
  const { event, t, highlightColour, showImages, showAudio, theme, i18n, roundButtons, information, eventTitle, venues, layout, partnerId } = props;
  const { name, venue, location, url, minCost, showFrom, audioPreviewUrl, imageUrl, status, sold_out: soldOut, cities, announcement_date: announceDate } = event;

  const date = {
    time: new Date(event.date),
    timezone: event.timezone,
  };
  const onSaleDate = {
    time: new Date(event.sale_start_date),
    timezone: event.timezone,
  };

  const urlParams = /\?/.test(imageUrl) ? '&w=500&h=500' : '?w=500&h=500';
  const eventUrl = `${url}?pid=${partnerId}`;

  let BookNowContainerComponent = BookNowContainer;
  if (information === 'simple' && !showImages) {
    BookNowContainerComponent = SimpleBookNowContainer;
  }

  let title = name;
  if (eventTitle === 'city') {
    title = location.city;
  }
  if (eventTitle === 'venue') {
    title = venue;
  }

  let Wrapper = WrapperNonGallery;
  if (layout === 'gallery') {
    Wrapper = styled.article`
      margin: 0;
    `;
  }

  let { city } = location;
  if (!city && cities.length > 0) {
    city = cities[0].name;
  }

  const showNewBadge = moment(announceDate).isAfter(moment().subtract(7, 'd'));
  const showVenueInfo = typeof venues === 'undefined' || venues.length !== 1;

  return (
    <Wrapper theme={theme}>
      <Container>
        <EventInfo>
          {showImages && (
            <ImageContainer>
              <a href={eventUrl} target="_blank" rel="noopener noreferrer noskim">
                <EventImage src={`${imageUrl}${urlParams}`} alt={name} />
              </a>
              {showAudio && audioPreviewUrl && <PlayAudio clip={audioPreviewUrl} />}
            </ImageContainer>
          )}
          <div>
            <StartTime>{t('event.start_time_v2', { date })}</StartTime>
            {status === 'announced' && <OnSaleTime>{t('event.on_sale_time', { onSaleDate })}</OnSaleTime>}
            <Name className="dice_event-title" colour={theme === 'dark' ? 'white' : 'black'} highlightColour={highlightColour} href={eventUrl} target="_blank" rel="noopener noreferrer noskim">{title}</Name>
            {showNewBadge && <NewBadge className="dice_new-badge" highlightColour={highlightColour}>{t('new')}</NewBadge>}
            {(information === 'regular' || information === 'full') && showVenueInfo && (
              <VenueInfo>
                {eventTitle !== 'venue' && <Venue>{venue}</Venue>}
                {eventTitle !== 'city' && city && <div>{city}</div>}
              </VenueInfo>
            )}
          </div>
        </EventInfo>
        <BookNowContainerComponent>
          <BookNow highlightColour={highlightColour} link={eventUrl} showFrom={showFrom} price={minCost} t={t} status={status} soldOut={soldOut} roundButtons={roundButtons} />
        </BookNowContainerComponent>
      </Container>
      {(information === 'full') && <ExtraInfo event={event} highlightColour={highlightColour} language={i18n.language} theme={theme} t={t} />}
      <JSONLD content={getJSONLDFromEvent(event)} />
    </Wrapper>
  );
};

Event.defaultProps = {
  venues: undefined,
};

Event.propTypes = {
  event: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  highlightColour: PropTypes.string.isRequired,
  showImages: PropTypes.bool.isRequired,
  showAudio: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  roundButtons: PropTypes.bool.isRequired,
  information: PropTypes.string.isRequired,
  eventTitle: PropTypes.string.isRequired,
  venues: PropTypes.arrayOf(PropTypes.string),
  layout: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
};

export default Event;
