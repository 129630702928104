import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Line from './Line';
import { getButtonColour, getBorderColour } from '../../styles/get-colour';
import media from '../../styles/media';

const StyledLoadMoreButton = styled.button`
  text-align: center;
  margin: 0 auto;
  height: 45px;
  padding: 0 16px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  min-width: 190px;
  width: 0;
  white-space: nowrap;
  ${props => getButtonColour(props)};
  border: 2px solid ${props => getBorderColour(props)};
  box-sizing: border-box;
  transition: all 0.15s ease-in-out;
  user-select: none;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;

  &:focus {
    color: ${props => getButtonColour(props)};
    outline: none;
  }

  ${media.phone`
    font-size: 12px;
    min-width: 140px;
    height: 35px;
  `};
`;

const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: ${({ eventStyle }) => (eventStyle === 'gallery' ? '10px' : 0)};
`;

class LoadMoreButton extends PureComponent {
  loadMore() {
    const { eventsPaginateRequest } = this.props.eventsActions;
    eventsPaginateRequest({
      apiKey: this.props.apiKey,
      next: this.props.events.links.next
    });

    window.dice_ga('send', 'event', 'Event List', 'click', 'Load More');
  }

  render() {
    return (
      <ButtonWrapper eventStyle={this.props.eventStyle}>
        {this.props.eventStyle === 'gallery' && (
          <Line theme={this.props.theme} />
        )}
        <StyledLoadMoreButton
          theme={this.props.theme}
          buttonBackgroundColor={this.props.buttonBackgroundColor}
          onClick={e => this.loadMore()}>
          {this.props.t('load_more')}
        </StyledLoadMoreButton>
        <Line theme={this.props.theme} />
      </ButtonWrapper>
    );
  }
}

export default LoadMoreButton;
