import { css } from 'styled-components';

export const breakPoints = {
  phone: {
    from: 0,
    to: 599,
  },
  tablet: {
    from: 600,
    to: 899,
  },
  tabletLarge: {
    from: 900,
    to: 1199,
  },
  laptop: {
    from: 1200,
    to: 1799,
  },
  desktop: {
    from: 1800,
    to: undefined,
  },
};

const media = {
  phone: (...args) => css`
    @media (max-width: ${breakPoints.phone.to}px) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media (min-width: ${breakPoints.tablet.from}px) {
      ${css(...args)};
    }
  `,
  belowLaptop: (...args) => css`
    @media (max-width: ${breakPoints.tabletLarge.to}px) {
      ${css(...args)};
    }
  `,
  tabletLarge: (...args) => css`
    @media (min-width: ${breakPoints.tabletLarge.from}px) {
      ${css(...args)};
    }
  `,
  laptop: (...args) => css`
    @media (min-width: ${breakPoints.laptop.from}px) {
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    @media (min-width: ${breakPoints.desktop.from}px) {
      ${css(...args)};
    }
  `,
};

export default media;
