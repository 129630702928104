function isGuestlist(event = {}) {
  return (event.flags || []).includes('guestlist');
}

export default (event) => {
  if (!event.sold_out || isGuestlist(event)) {
    return 'http://schema.org/LimitedAvailability';
  }

  return 'http://schema.org/SoldOut';
};
