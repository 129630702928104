import getJSONLDOffersForEvent from './get-jsonld-offers-for-event';

export default (event, extras = {}) => {
  let jsonld = {
    '@context': 'http://schema.org',
    '@type': 'MusicEvent',

    name: event.name,
    image: `${event.background_image}?min-w=720&fit=crop`, // Google: We recommend that images are 1920px wide (the minimum width is 720px).
    url: `https://dice.fm/event/${event.perm_name}`, // TODO: add event.hash
    startDate: event.date,
    doorTime: event.date,
    eventStatus: 'EventScheduled',
    description: event.description,
    location: {
      '@type': 'Place',
      name: event.venue,
      address: event.address,
    },

    offers: getJSONLDOffersForEvent(event),

    performers: [],
  };
  if (event.date_end) {
    jsonld.endDate = event.date_end;
  }

  if (event.artists) {
    event.artists.forEach((artist) => {
      jsonld.performers.push({
        '@type': 'MusicGroup',
        name: artist,
      });
    });
  }

  jsonld = {
    ...jsonld,
    ...extras,
  };

  return [jsonld];
};
