import isTicketTypes from './is-ticket-types';

export default (event) => {
  if (!isTicketTypes(event)) {
    return undefined;
  }
  const { ticket_types: ticketTypes } = event;
  let fromPrice = ticketTypes[0].price;
  for (let i = 1; i < ticketTypes.length; i += 1) {
    if (ticketTypes[i].price.total < fromPrice.total) {
      fromPrice = ticketTypes[i].price;
    }
  }
  fromPrice.currency = event.currency;
  return fromPrice;
};
