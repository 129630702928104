export default (number, commaDelimeter = false) => {
  if (typeof number !== 'number') {
    return '';
  }
  const parts = number.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (parts[1] === '00') {
    return parts[0];
  }

  const delimeter = commaDelimeter ? ',' : '.';
  return parts.join(delimeter);
};
