import axios from 'axios';

// ------------------------------------
// Constants
// ------------------------------------
export const EVENTS_REQUEST = 'EVENTS_REQUEST';
export const EVENTS_SUCCESS = 'EVENTS_SUCCESS';
export const EVENTS_PAGINATE_REQUEST = 'EVENTS_PAGINATE_REQUEST';
export const EVENTS_PAGINATE_SUCCESS = 'EVENTS_PAGINATE_SUCCESS';
export const EVENTS_FAILURE = 'EVENTS_FAILURE';

// ------------------------------------
// Actions
// ------------------------------------

export const eventsSuccess = data => (
  {
    type: EVENTS_SUCCESS,
    data,
  }
);

export const eventsPaginateSuccess = data => (
  {
    type: EVENTS_PAGINATE_SUCCESS,
    data,
  }
);

export const eventsFailure = error => (
  {
    type: EVENTS_FAILURE,
    error,
  }
);

export const eventsRequest = ({
  apiKey,
  venueName,
  promoterName,
  artistName,
  venues,
  artists,
  promoters,
  tags,
  cities,
  currency,
  priceFrom,
  priceTo,
}) => (dispatch) => {
  dispatch({ type: EVENTS_REQUEST });
  const filterByVenue = venueName && venueName !== '' ? `&filter[venue]=${venueName}` : '';
  const filterByPromoter = promoterName && promoterName !== '' ? `&filter[promoter_name]=${promoterName}` : '';
  const filterByArtist = artistName && artistName !== '' ? `&filter[artist]=${artistName}` : '';

  // v2
  let fitlerByVenues = '';
  if (venues && venues.length > 0) {
    venues.forEach((venue) => {
      fitlerByVenues = `${fitlerByVenues}&filter[venues][]=${encodeURIComponent(venue)}`;
    });
  }
  let filterByPromoters = '';
  if (promoters && promoters.length > 0) {
    promoters.forEach((promoter) => {
      filterByPromoters = `${filterByPromoters}&filter[promoters][]=${encodeURIComponent(promoter)}`;
    });
  }
  let filterByArtists = '';
  if (artists && artists.length > 0) {
    artists.forEach((artist) => {
      filterByArtists = `${filterByArtists}&filter[artists][]=${encodeURIComponent(artist)}`;
    });
  }
  let filterByCities = '';
  if (cities && cities.length > 0) {
    cities.forEach((city) => {
      filterByCities = `${filterByCities}&filter[cities][]=${city}`;
    });
  }
  const filterByCurrency = currency && currency !== '' ? `&filter[currency]=${currency}` : '';
  const filterByPriceFrom = priceFrom && priceFrom !== '' ? `&filter[price_from]=${priceFrom}` : '';
  const filterByPriceTo = priceTo && priceTo !== '' ? `&filter[price_to]=${priceTo}` : '';

  let filterByTags = '';
  if (tags) {
    if (typeof tags === 'string') {
      filterByTags = tags !== '' ? `&filter[tags]=${tags}` : '';
    } else if (tags.length > 0) {
      tags.forEach((tag) => {
        filterByTags = `${filterByTags}&filter[tags][]=${tag}`;
      });
    }
  }


  const filters = `${filterByVenue}${filterByPromoter}${filterByArtist}${fitlerByVenues}${filterByPromoters}${filterByArtists}${filterByTags}${filterByCities}${filterByCurrency}${filterByPriceFrom}${filterByPriceTo}`;
  const url = `https://events-api.dice.fm/v1/events?page[size]=24${filters}`;
  return axios
    .get(url, {
      headers: { 'x-api-key': apiKey },
    })
    .then((res) => {
      if (res.data.errors) {
        const error = res.data.errors.detail;
        console.error(error);
        return dispatch(eventsFailure(error));
      }
      return dispatch(eventsSuccess(res.data));
    })
    .catch((error) => {
      console.error(error);
      return dispatch(eventsFailure(error));
    });
};

export const eventsPaginateRequest = ({ apiKey, next }) => dispatch => (
  axios
    .get(next, {
      headers: { 'x-api-key': apiKey },
    })
    .then(res => dispatch(eventsPaginateSuccess(res.data)))
    .catch((error) => {
      console.error(error);
      return dispatch(eventsFailure(error));
    })
);

export const actions = {
  eventsRequest,
  eventsSuccess,
  eventsPaginateRequest,
  eventsPaginateSuccess,
  eventsFailure,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [EVENTS_REQUEST]: state => ({ ...state, fetching: true }),
  [EVENTS_SUCCESS]: (state, action) => ({
    data: action.data.data,
    links: action.data.links,
    error: null,
    fetching: false,
  }),
  [EVENTS_PAGINATE_REQUEST]: state => ({ ...state, fetching: true }),
  [EVENTS_PAGINATE_SUCCESS]: (state, action) => {
    const events = [...state.data, ...action.data.data];
    return { data: events, links: action.data.links, fetching: false };
  },
  [EVENTS_FAILURE]: (state, action) => ({
    ...state,
    error: action.error,
    fetching: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  data: null,
  links: null,
  error: null,
  fetching: false,
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
