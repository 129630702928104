import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './modules/reducers';

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export default function configureStore() {
  const store = createStore(reducers, {}, bindMiddleware([thunk]));

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./modules/reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
