import formatCostObj from '../currency/cost-obj';
import isTicketTypes from './is-ticket-types';
import getEventFromCost from './get-event-from-cost';

export default (event, locale) => {
  const multipleTicketTypes = isTicketTypes(event, 1);
  const formattedCost = formatCostObj(getEventFromCost(event), 1, locale);
  let string = 'event.cost.single';

  if (multipleTicketTypes) {
    string = 'event.cost.ticket_type';
  }
  if (formattedCost === 'Free') {
    string = 'event.cost.free';
  }
  if (event.sold_out) {
    string = 'event.cost.waiting_list';
  }
  if (formattedCost === 'Free' && (event.flags || []).includes('guestlist')) {
    string = 'event.cost.competition';
  }
  /*
  if (event.status === 'off-sale') {
    string = 'event.cost.cancelled'
  }
  */

  return { string, cost: formattedCost };
};
