import React, { PureComponent } from 'react';
import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';

import Line from './Line';
import { getButtonColour, getBorderColour } from '../../styles/get-colour';
import getJSONLDFromEvent from '../../utils/get-jsonld-from-event';
import formatEvent from '../../utils/format-event';
import JSONLD from '../../utils/JSONLD';

const Article = styled.div`
  position: relative;
`;

const EventCover = styled.img`
  position: relative;
  width: 75px;
  height: 75px;
  margin-right: 60px;
`;

const Event = styledContainerQuery.div`
  display: flex;
  flex-direction: row;
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;

  &:container(max-width: 580px) {
    ${EventCover} {
      display: none;
    }
  }
`;

const EventName = styledContainerQuery.div`
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 8px;

  &:container(max-width: 599px) {
    font-size: 16px;
    margin-top: 0;
  }
`;

const EventStartTime = styledContainerQuery.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => (theme === 'light' ? 'rgba(51, 51, 51, 0.5)' : '#C1C1C1')};
  margin-bottom: 8px;

  &:container(max-width: 599px) {
    font-size: 15px;
    margin-bottom: ${({ venueName }) => (venueName !== '' ? '19px' : '8px')};
  }
`;

const EventVenue = styledContainerQuery(EventStartTime)`
  &:container(max-width: 599px) {
    font-size: 15px;
    margin-bottom: 19px;
  }
`;

const ButtonWrapper = styled.a`
  position: absolute;
  right: 18px;
  margin-top: 15px;
  min-width: 150px;
`;

const EventBuyButton = styled.button`
  text-align: center;
  margin: 0 auto;
  height: 45px;
  padding: 0 16px;
  text-decoration: none;
  cursor: pointer;
  min-width: 150px;
  white-space: nowrap;
  ${props => getButtonColour(props)};
  border: 2px solid ${props => getBorderColour(props)};
  box-sizing: border-box;
  transition: all 0.15s ease-in-out;
  user-select: none;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
`;

const EventInfo = styledContainerQuery.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:container(min-width: 415px and max-width: 1000px) {
    ${EventName} {
      max-width: 45%;
    }
    ${EventStartTime} {
      max-width: 45%;
    }
  }

  &:container(min-width: 1000px) {
    ${EventName} {
      max-width: 80%;
    }
    ${EventStartTime} {
      max-width: 80%;
    }
  }

  &:container(max-width: 420px) {
    ${ButtonWrapper} {
      position: relative;
      right: 0;
      margin-top: 0;
    }
    ${EventBuyButton} {
      font-size: 12px;
      height: 35px;
    }
    ${EventCover} {
      display: none;
    }
  }
`;

class EventCard extends PureComponent {
  trackBuyButton() {
    window.dice_ga('send', 'event', 'Event', 'click', 'Buy Tickets');
  }

  render() {
    const { t, i18n, theme, cover, buttonBackgroundColor } = this.props;
    const event = formatEvent(this.props.event, i18n.language);
    const url = `${event.url}?pid=${this.props.partnerId}`;

    const date = {
      time: new Date(event.date),
      timezone: event.timezone,
    };

    return (
      <Article>
        <Event>
          {cover && (
            <EventCover
              src={`${event.background_image}?w=150&h=150&fit=crop`}
              alt={event.name}
              title={`${event.name} at ${event.venue}`}
            />
          )}
          <EventInfo>
            <EventName>{event.name}</EventName>
            <EventStartTime theme={theme} venueName={this.props.venueName}>
              {t('event.date', { date })}
              {t('event.start_time', { date })}
            </EventStartTime>
            {(!this.props.venueName || this.props.venueName === '') && (
              <EventVenue theme={theme}>{event.venue}</EventVenue>
            )}
            <ButtonWrapper href={url} target="_blank" rel="noopener noskim">
              <EventBuyButton
                theme={theme}
                buttonBackgroundColor={buttonBackgroundColor}
                onClick={e => this.trackBuyButton()}>
                {t(event.formatted_cost.string, {
                  cost: event.formatted_cost.cost
                })}
              </EventBuyButton>
            </ButtonWrapper>
          </EventInfo>
        </Event>
        <JSONLD content={getJSONLDFromEvent(event)} />
        <Line theme={theme} />
      </Article>
    );
  }
}

export default EventCard;
