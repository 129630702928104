import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Line from './Line';
import media from '../../styles/media';

const Article = styled.div`
  position: relative;
`;

const SkeletonEvent = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;
`;

const SkeletonEventInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const SkeletonEventName = styled.div`
  height: 15px;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};
  margin-top: 8px;
  margin-bottom: 8px;

  ${media.phone`
    width: 250px;
    margin-top: 0;
  `};

  ${media.tablet`
    width: 300px;
  `};

  ${media.laptop`
    width: 420px;
  `};
`;

const SkeletonEventStartTime = styled.div`
  width: 80%;
  height: 15px;
  margin-bottom: 8px;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};

  ${media.phone`
    margin-bottom: 19px;
  `};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 18px;
  margin-top: 15px;

  ${media.phone`
    position: relative;
    right: 0;
    margin-top: 0;
  `};
`;

const SkeletonEventBuyButton = styled.div`
  height: 45px;
  padding: 0 16px;
  min-width: 170px;
  width: 0;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};

  ${media.phone`
    min-width: 110px;
    height: 35px;
  `};
`;

const SkeletonEventCover = styled.div`
  width: 75px;
  height: 75px;
  margin-right: 60px;
  background-color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.1);' : '#414141'};

  ${media.phone`
    display: none;
  `};
`;

class SkeletonEventCard extends PureComponent {
  render() {
    const { theme, cover } = this.props;

    return (
      <Article>
        <SkeletonEvent>
          {cover && <SkeletonEventCover theme={theme} />}
          <SkeletonEventInfo>
            <SkeletonEventName theme={theme} />
            <SkeletonEventStartTime theme={theme} />
            <ButtonWrapper>
              <SkeletonEventBuyButton theme={theme} />
            </ButtonWrapper>
          </SkeletonEventInfo>
        </SkeletonEvent>
        <Line theme={theme} />
      </Article>
    );
  }
}

export default SkeletonEventCard;
