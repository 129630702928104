import React, { PureComponent } from 'react';
import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';

import media from '../../styles/media';
import { getBorderColour, getButtonColour } from '../../styles/get-colour';
import formatEvent from '../../utils/format-event';
import getJSONLDFromEvent from '../../utils/get-jsonld-from-event';
import JSONLD from '../../utils/JSONLD';

const Article = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0;
  max-width: 420px;

  ${media.phone`
    width: 100%;
    padding: 5px 0;
  `};
`;

const Event = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ theme }) =>
    theme === 'light'
      ? '1px solid rgba(51, 51, 51, 0.2)'
      : '1px solid rgba(255, 255, 255, 0.2)'};
`;

const EventInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const EventName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-top: 8px;
  margin-bottom: 8px;

  ${media.phone`
    font-size: 16px;
    margin-top: 0;
  `};
`;

const EventStartTime = styledContainerQuery.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: ${({ theme }) =>
    theme === 'light' ? 'rgba(51, 51, 51, 0.5)' : '#C1C1C1'};
  margin-bottom: 8px;

  &:container(max-width: 599px) {
    font-size: 15px;
    margin-bottom: ${({ venueName }) => (venueName !== '' ? '19px' : '8px')};
  }
`;

const EventVenue = styledContainerQuery(EventStartTime)`
  &:container(max-width: 599px) {
    font-size: 15px;
    margin-bottom: 19px;
  }
`;

const EventBuyButton = styled.button`
  text-align: center;
  margin: 0 auto;
  height: 45px;
  padding: 0 16px;
  text-decoration: none;
  cursor: pointer;
  min-width: 190px;
  width: 100%;
  white-space: nowrap;
  ${props => getButtonColour(props)};
  border: 2px solid ${props => getBorderColour(props)};
  box-sizing: border-box;
  transition: all 0.15s ease-in-out;
  user-select: none;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }

  ${media.phone`
    font-size: 12px;
    min-width: 140px;
    height: 35px;
  `};
`;

const EventCover = styled.img`
  background-size: cover;
  height: auto;
  width: 100%;
  min-width: 100px;
  max-width: 420px;
`;

class EventTile extends PureComponent {
  trackBuyButton() {
    window.dice_ga('send', 'event', 'Event', 'click', 'Buy Tickets');
  }

  render() {
    const { t, i18n, theme, cover, buttonBackgroundColor } = this.props;
    const event = formatEvent(this.props.event, i18n.language);
    const url = `${event.url}?pid=${this.props.partnerId}`;

    const date = {
      time: new Date(event.date),
      timezone: event.timezone,
    };

    return (
      <Article>
        <Event theme={theme}>
          <EventCover
            src={`${event.background_image}?w=420&h=420&fit=crop&crop=faces`}
            alt={event.name}
            title={`${event.name} at ${event.venue}`}
          />
          <EventInfo>
            <EventName>{event.name}</EventName>
            <EventStartTime theme={theme} venueName={this.props.venueName}>
              {t('event.date', { date })}
              {t('event.start_time', { date })}
            </EventStartTime>
            {(!this.props.venueName || this.props.venueName === '') && (
              <EventVenue theme={theme}>{event.venue}</EventVenue>
            )}
            <a href={url} target="_blank" rel="noopener noskim">
              <EventBuyButton
                theme={theme}
                buttonBackgroundColor={buttonBackgroundColor}
                onClick={e => this.trackBuyButton()}>
                {t(event.formatted_cost.string, {
                  cost: event.formatted_cost.cost
                })}
              </EventBuyButton>
            </a>
          </EventInfo>
        </Event>
        <JSONLD content={getJSONLDFromEvent(event)} />
      </Article>
    );
  }
}

export default EventTile;
