import formatNumber from '../number';
import symbol from './symbol';

const REVERSE_CURRENCIES = ['fr'];

export default (number, currency, locale = 'en') => {
  if (typeof number !== 'number') {
    return '';
  }

  if (REVERSE_CURRENCIES.includes(locale)) {
    return `${formatNumber(number, true)}${symbol(currency)}`;
  }

  return symbol(currency) + formatNumber(number);
};
