import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment-timezone';

import translationsEN from '../locales/en/translations.json';
import translationsFR from '../locales/fr/translations.json';
import translationsES from '../locales/es/translations.json';
import translationsIT from '../locales/it/translations.json';

require('moment/locale/fr');
require('moment/locale/es');
require('moment/locale/it');

moment.updateLocale('fr', {
  weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  monthsShort: [
    'Janv',
    'Févr',
    'Mars',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Août',
    'Sept',
    'Oct',
    'Nov',
    'Déc',
  ],
});

// TODO: moment timezone?

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      translations: translationsEN,
    },
    fr: {
      translations: translationsFR,
    },
    es: {
      translations: translationsES,
    },
    it: {
      translations: translationsIT,
    },
  },
  fallbackLng: 'en',
  load: 'languageOnly',
  whitelist: ['en', 'fr', 'es', 'it'],
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (value && value.timezone) {
        return moment(value.time)
          .tz(value.timezone)
          .locale(lng)
          .format(format);
      }
      return value;
    },
  },
  react: {
    wait: true,
  },
});

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
