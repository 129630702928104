import moment from 'moment';
import getJSONLDAvailabilityForEvent from './get-jsonld-availability-for-event';

function isPastEvent(event) {
  const { date, date_end: endDate } = event;

  if (endDate) {
    return moment().isAfter(endDate);
  }

  // assume isPastEvent if event started over 10 days ago
  return moment().diff(date, 'day') > 10;
}

export default (event) => {
  if (isPastEvent(event)) {
    return [];
  }

  const offer = {
    '@type': 'Offer',
    name: 'DICE ticket',
    category: 'primary',
    availability: getJSONLDAvailabilityForEvent(event),
    url: `https://dice.fm/event/${event.perm_name}`,
    validFrom: event.sale_start_date,
  };

  if (event.ticket_types[0]) {
    offer.price = event.ticket_types[0].price.total / 100;
    offer.priceCurrency = event.currency;
  }

  return [offer];
};
