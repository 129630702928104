import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';
import { withNamespaces } from 'react-i18next';

import * as eventsActions from '../redux/modules/events';

import EventCard from '../components/v1/EventCard';
import EventTile from '../components/v1/EventTile';
import SkeletonEventCard from '../components/v1/SkeletonEventCard';
import SkeletonEventTile from '../components/v1/SkeletonEventTile';
import LoadMoreButton from '../components/v1/LoadMoreButton';
import PoweredByDICE from '../components/v1/PoweredByDICE';
import Line from '../components/v1/Line';
import media from '../styles/media';

const Container = styled.div`
  margin: 10px;
  font-family: ${({ fontFamily }) => fontFamily};
  color: ${({ theme }) => (theme === 'light' ? '#000' : '#FFF')};
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  line-height: 34px;
  font-size: 28px;
  letter-spacing: 0.05em;
  padding-top: 20px;
  margin: 0;

  ${media.phone`
    font-size: 19px;
  `};
`;

const Error = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 16px;
  letter-spacing: 0.05em;
  text-align: center;
  padding-top: 270px;
  color: ${({ theme }) => (theme === 'light' ? '#000' : '#F8F8F8')};

  ${media.phone`
    padding-top: 200px;
  `};
`;

const ErrorContact = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  padding-bottom: 270px;
  color: ${({ theme }) => (theme === 'light' ? '#000' : '#F8F8F8')};

  ${media.phone`
    padding-bottom: 200px;
  `};
`;

const EventWrapper = styledContainerQuery.div``;

const EventListWrapper = styledContainerQuery.div`
  display: ${({ eventStyle }) => (eventStyle === 'gallery' ? 'flex' : '')};
  flex-wrap: ${({ eventStyle }) => (eventStyle === 'gallery' ? 'wrap' : '')};
  padding-top: ${({ eventStyle }) => (eventStyle === 'gallery' ? '10px' : 0)};
  justify-content: center;

  &:container(max-width: 730px) {
    ${EventWrapper} {
      width: 100%;
    }
  }

  &:container(min-width: 730px and max-width: 1000px) {
    ${EventWrapper} {
      width: ${({ eventStyle }) => (eventStyle === 'gallery' ? 'calc(33% - 10px)' : '')};
      padding: 0 5px 0 5px;
    }
  }

  &:container(min-width: 1000px) {
    ${EventWrapper} {
      width: ${({ eventStyle }) => (eventStyle === 'gallery' ? 'calc(33% - 30px)' : '')};
      padding: 0 15px 0 15px;
    }
  }
`;

class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    const { eventsActions: eA, apiKey, venueName, promoterName, tags, artistName } = this.props;
    if (apiKey) {
      eA.eventsRequest({
        apiKey,
        venueName,
        promoterName,
        tags,
        artistName,
      });
    } else {
      this.setState({ error: true });
    }
  }

  renderEventList() {
    const { events, eventStyle } = this.props;
    const eventList = events.data;

    return eventList.map(event => (
      <EventWrapper key={`event-${event.id}`} eventStyle={eventStyle}>
        {eventStyle === 'gallery' ? (
          <EventTile event={event} {...this.props} />
        ) : (
          <EventCard event={event} {...this.props} />
        )}
      </EventWrapper>
    ));
  }

  render() {
    let deelsTitle;
    const { t, events, title, venueName, eventStyle } = this.props;

    if (title && title !== '') {
      deelsTitle = title;
    } else {
      deelsTitle = (
        <>
          {t('event_list.upcoming')}
          {venueName && venueName !== '' && t('event_list.at_venue', { venueName })}
        </>
      );
    }

    const { error } = this.state;
    if (error || events.error || (events.data && events.data.length === 0)) {
      return (
        <Container {...this.props}>
          <Title {...this.props}>{deelsTitle}</Title>
          <Error {...this.props}>{t('event_list.no_upcoming')}</Error>
          <ErrorContact {...this.props}>
            {t('event_list.error')}{' '}
            <a href="mailto:help@dice.fm" target="_top">
              help@dice.fm
            </a>
          </ErrorContact>
          <Line {...this.props} />
          <PoweredByDICE {...this.props} />
        </Container>
      );
    }

    if (events.data) {
      return (
        <Container {...this.props}>
          <Title {...this.props}>{deelsTitle}</Title>
          <EventListWrapper eventStyle={eventStyle}>
            {this.renderEventList()}
          </EventListWrapper>
          {events.links.next && <LoadMoreButton {...this.props} />}
          <PoweredByDICE {...this.props} />
        </Container>
      );
    }

    return (
      <Container {...this.props}>
        <Title {...this.props}>{deelsTitle}</Title>
        {eventStyle === 'gallery' ? (
          <EventListWrapper eventStyle={eventStyle}>
            <SkeletonEventTile {...this.props} />
            <SkeletonEventTile {...this.props} />
            <SkeletonEventTile {...this.props} />
            <SkeletonEventTile {...this.props} />
            <SkeletonEventTile {...this.props} />
            <SkeletonEventTile {...this.props} />
          </EventListWrapper>
        ) : (
          <EventListWrapper>
            <SkeletonEventCard {...this.props} />
            <SkeletonEventCard {...this.props} />
            <SkeletonEventCard {...this.props} />
            <SkeletonEventCard {...this.props} />
            <SkeletonEventCard {...this.props} />
          </EventListWrapper>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  events: state.events,
});

const mapDispatchToProps = dispatch => ({
  eventsActions: bindActionCreators(eventsActions, dispatch),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withNamespaces(),
)(EventList);
