import formatCost from './formatters/event/format-cost';

export default (data, locale) => {
  const event = typeof data.toJSON === 'function' ? data.toJSON() : data;
  return {
    ...event,
    formatted_cost: formatCost(event, locale),
    background_image: event.images[0],
  };
};
