import React from 'react';
import styled from 'styled-components';
import styledContainerQuery from 'styled-container-query';

// const Container = styled.div`
//   padding: 24px 0;
// `;

const FakeImage = styled.div`
  width: 100%;
  height: 300px;
  background: rgba(0,0,0,0.3);
`;

const EventInfo = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const EventLine = styled.div`
  width: 100%;
  height: 20px;
  background: ${({ light }) => (light ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.3)')};
  margin-bottom: 4px;

  &:last-child {
    margin: 0;
  }
`;

const Container = styledContainerQuery.div`
  padding: 24px 0;

  &:container(min-width: 500px) {
    display: flex;

    ${FakeImage} {
      min-width: 160px;
      max-width: 160px;
      min-height: 160px;
      max-height: 160px;
    }

    ${EventInfo} {
      padding-left: 16px;
    }
  }
`;

const SkeletonEvent = () => (
  <Container>
    <FakeImage />
    <EventInfo>
      <EventLine light />
      <EventLine />
      <EventLine light />
      <EventLine />
    </EventInfo>
  </Container>
);

export default SkeletonEvent;
