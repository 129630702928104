import isTicketTypes from './formatters/event/is-ticket-types';
import formatCostObj from './formatters/currency/cost-obj';
import getEventFromCost from './formatters/event/get-event-from-cost';
import getPreviewUrl from './formatters/event/get-event-audio-preview';

export default (data, locale) => {
  const event = typeof data.toJSON === 'function' ? data.toJSON() : data;
  return {
    ...event,
    showFrom: isTicketTypes(event, 1),
    minCost: formatCostObj(getEventFromCost(event), 1, locale),
    audioPreviewUrl: getPreviewUrl(event),
    imageUrl: (event.event_images && event.event_images.square) ? event.event_images.square : event.images[0],
  };
};
