import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import getContrastColour from '../styles/get-contrast-colour';

const Button = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  background: ${({ background }) => background};
  color: ${({ background }) => getContrastColour(background)};
  padding: 12px 32px;
  text-align: center;
  transition: opacity 0.2s ease;
  box-sizing: border-box;
  min-width: 154px;
  border-radius: ${({ roundButtons }) => (roundButtons ? '160px' : '0')};

  &:hover {
    opacity: 0.75;
  }
`;

const PriceContainer = styled.div`
  min-width: 90px;
  text-align: right;
  margin-left: 12px;
`;

const Price = styled.div`
  font-size: 24px;
  line-height: 1.4;
`;

const From = styled.div`
  font-size: 16px;
  opacity: 0.5;
`;

const getMessageKey = (status, soldOut) => {
  if (status === 'announced') {
    return 'event.cost.remind_me';
  }
  if (soldOut) {
    return 'event.cost.waiting_list';
  }
  return 'event.cost.book_now';
};

const trackBuyButton = () => {
  window.dice_ga('send', 'event', 'Event', 'click', 'Buy Tickets');
};

const BookNow = (props) => {
  const { highlightColour, link, price, showFrom, t, status, soldOut, roundButtons } = props;
  const messageKey = getMessageKey(status, soldOut);
  return (
    <>
      <Button className="dice_book-now" roundButtons={roundButtons} background={highlightColour} href={link} target="_blank" rel="noopener noskim noreferrer" onClick={() => trackBuyButton()}>{t(messageKey)}</Button>
      <PriceContainer className="dice_price-container">
        {showFrom && <From>{t('event.cost.from')}</From>}
        <Price className="dice_price">{price}</Price>
      </PriceContainer>
    </>
  );
};

BookNow.defaultProps = {
  highlightColour: '#3c74ff',
  showFrom: false,
};

BookNow.propTypes = {
  highlightColour: PropTypes.string,
  link: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  soldOut: PropTypes.bool.isRequired,
  showFrom: PropTypes.bool,
  t: PropTypes.func.isRequired,
  roundButtons: PropTypes.bool.isRequired,
};

export default BookNow;
